p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  p {
    font-size: 18px;
    max-width: 600px;
    margin: 0 auto;
  }
}