.home {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  flex: 1;
  display: block;
  margin: 0 auto;
  /*max-height: 25%;*/
  max-width: 20%;
}

.image {
  flex: 1;
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}