.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.hamburger > div {
  width: 2rem;
  height: 0.25rem;
  background-color: #333;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.hamburger.open > div:first-child {
  transform: rotate(45deg) translate(0.3rem, 0.6rem);
}

.hamburger.open > div:nth-child(2) {
  opacity: 0;
}

.hamburger.open > div:last-child {
  transform: rotate(-45deg) translate(0.3rem, -0.6rem);
}

.menu-overlay {
  position: absolute;
  top: 2.5rem;
  left: 0; /* Change from "right: 0" to "left: 0" */
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.menu-items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-items li {
  padding: 0.5rem;
  cursor: pointer;
}

.menu-items li:hover {
  background-color: #f0f0f0;
}
